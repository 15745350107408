<template>
  <v-row>
    <v-sheet
      height="66vh"
      width="100%"
      elevation="5"
      style="box-shadow: 0 3px 5px -1px rgba(42, 204, 204, 0.2), 0 5px 8px 0 rgba(42, 204, 204, 0.14), 0 1px 14px 0 rgba(42, 204, 204, 0.12) !important;"
    >
      <v-calendar
        id="calendar"
        ref="calendar"
        :value="calendar"
        color="primary"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        :type="rangeType"
        :events="activityEvents"
        :event-color="getEventColor"
        :start="selectedRangeTarget"
        :end="selectedRangeTarget"
        style="border-radius: 8px;"
        @click:date="date => $emit('setToday', date)"
        @click:day="openCreateDialog"
        @click:time="time => { openCreateDialog(time); currentDayData = time }"
        @click:more="getMoreEvents"
        @contextmenu:event="openDeleteDialog"
      >
        <template #day-body="{ date, week, present }">
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date, dashed: !present }"
            :style="{ top: nowY }"
          />
        </template>
        <template #day="{ date, hour }">
          <div
            :id="date + hour"
          />
        </template>
        <template #interval="{ date, hour }">
          <div
            :id="date + hour"
          />
        </template>
        <template #day-header="{ weekday, present, date, hour }">
          <div
            :id="date + hour"
          />
          <div
            v-if="[1, 0].includes(weekday)"
            :id="date + hour"
            :class="weekday === 0 ? 'd-flex justify-end' : null"
          >
            <div
              style=" position: absolute;
                          top: 30px;
                          cursor: pointer;"
            >
              <v-icon
                v-if="weekday === 1"
                large
                color="primary"
                @click.stop="prevWeek()"
              >
                mdi-chevron-left
              </v-icon>
              <v-icon
                v-if="weekday === 0"
                large
                color="primary"
                @click.stop="nextWeek()"
              >
                mdi-chevron-right
              </v-icon>
            </div>
          </div>
          <div
            v-if="rangeType === 'day'"
            :id="date + hour"
            :class="weekday === 6 ? 'd-flex justify-end' : null"
          >
            <div
              style=" position: absolute;
                          top: 30px;
                          display: flex;
                          justify-content: space-between;
                          width: 100%;
                          cursor: pointer;"
            >
              <v-icon
                large
                color="primary"
                @click.stop="prevDay()"
              >
                mdi-chevron-left
              </v-icon>
              <v-icon
                large
                color="primary"
                @click.stop="nextDay()"
              >
                mdi-chevron-right
              </v-icon>
            </div>
          </div>
          <div v-if="present" style="border-bottom: 4px solid #2acccc;" />
        </template>
        <template #event="eventData">
          <div
            v-if="eventData.timed || !eventData.timed"
            :id="eventData.event.id"
            class="d-flex"
            @click.stop="handleClickOnEvent(eventData)"
          >
            <aside :style="{
              backgroundColor: eventData.event.color,
            }"
            />
            <div
              class="d-flex flex-column justify-center"
              :style="{
                backgroundColor: `${eventData.event.color}80`,
              }"
            >
              <span
                class="text-truncate font-weight-medium"
                style="max-width: 8rem;"
                v-text="eventData.event.name"
              />
              <span
                v-if="eventData.event.type === 'guildMatch'"
                class="text-truncate font-weight-medium"
                style="max-width: 8rem;"
                v-text="eventData.event.time"
              />
              <span
                v-for="(label, i) in teamLabel(eventData)"
                :key="i"
                style="font-size: 0.75em;"
                v-text="label"
              />
            </div>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
    <template v-if="rangeType === 'month'">
      <div class="month-controls">
        <div class="d-flex justify-space-between">
          <v-icon
            large
            color="primary"
            @click.stop="prevMonth()"
          >
            mdi-chevron-left
          </v-icon>
          <v-icon
            large
            color="primary"
            @click.stop="nextMonth()"
          >
            mdi-chevron-right
          </v-icon>
        </div>
      </div>
    </template>
    <calendar-create-dialog
      v-if="clicked && currentDayData"
      :clicked-day-data="currentDayData"
      :position-dialog="positionDialog"
      @closeCreateDialog="closeCreateDialog"
    />
    <calendar-delete-dialog
      v-if="clickedDelete && currentDayData"
      :clicked-day-data="currentDayData"
      :position-dialog="positionDialog"
      :selected-event="selectedEvent"
      @closeDeleteDialog="closeDeleteDialog"
      @deleteThisActivity="params => deleteActivity(params)"
    />
    <calendar-event-card
      v-if="openCard && cardData"
      :clicked-day-data="currentDayData"
      :position-detail-modal="positionDetailModal"
      :card-data="cardData"
      :colors="colors"
      @closeDetailCard="closeDetailCard"
      @redirectToEvent="params => redirectToEventDetails(params)"
    />
  </v-row>
</template>

<script>
import { DateTime } from 'luxon'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'CalendarBody',
  components: {
    CalendarCreateDialog: () => import('@/modules/calendar/CalendarCreateDialog'),
    CalendarDeleteDialog: () => import('@/modules/calendar/CalendarDeleteDialog'),
    CalendarEventCard: () => import('@/modules/calendar/CalendarEventCard'),
  },
  mixins: [clickaway],
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    calendar: {
      type: String,
      required: true,
    },
    activities: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: null,
    },
    teams: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Object,
      required: true,
    },
    rangeType: {
      type: String,
      required: true,
    },
    selectedTeams: {
      type: Array,
      default: () => [],
    },
    selectedRangeTarget: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      clicked: false,
      clickedDelete: false,
      currentDayData: {},
      selectedEvent: {},
      currentElementPosition: {},
      nowY: '-10px',
      cardData: {},
      openCard: false,
      doubleClickTimer: null,
    }
  },
  computed: {
    activityEvents({ type, activities, getEventColor, selectedTeams }) {
      return activities?.filter(activity => type ? activity.type === type : true)
        .filter(activity => selectedTeams.length
          ? activity.userSources.teamIds.some(id => selectedTeams.map(team => team.id).includes(id))
          : true)
        .map(activity => ({
          ...activity,
          name: `${activity.type !== 'guildMatch' ? ` ${activity.type} - ` : ''}${activity.name ? `${activity.name}` : ''}`,
          ...(activity.type === 'guildMatch' && { time: this.formatEventTime(activity) }),
          ...(activity.type !== 'guildMatch' && { teamId: activity.userSources.teamIds[0] }),
          start: activity?.date.start.toDate(),
          end: activity?.date.end.toDate(),
          color: getEventColor(activity),
          timed: true,
        })) ?? []
    },
    positionDialog({ currentElementPosition }) {
      const { top, left } = currentElementPosition
      return {
        position: 'relative',
        width: '240px',
        height: 'auto',
        top: `${top - 24}px`,
        left: `${left + 24}px`,
      }
    },
    positionDetailModal({ currentElementPosition, rangeType }) {
      const { top, left, width } = currentElementPosition
      return {
        position: 'relative',
        width: '400px',
        height: '192px',
        top: `${top}px`,
        left: `${rangeType === 'day' ? document.innerWidth / 2 : left - width}px`,
        borderRadius: '8px',
      }
    },
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs?.calendar) {
        this.scrollToTime()
        this.updateTime()
      }
    })
  },
  methods: {
    getCurrentTime() {
      return {
        hour: new Date().getHours(),
        minute: new Date().getMinutes(),
      }
    },
    scrollToTime() {
      const currentTime = this.getCurrentTime()
      const { hour } = currentTime

      this.$refs?.calendar?.scrollToTime(`${hour}:00`)

      this.$refs?.calendar?.updateTimes()
      this.nowY = `${this.$refs?.calendar?.timeToY(currentTime)}px`
    },
    updateTime() {
      setInterval(() => {
        this.scrollToTime()
      }, 60 * 1000)
    },
    getEventColor(event) {
      return this.colors[event.type]
    },
    openCreateDialog(day) {
      this.currentElementPosition = document.getElementById(day.date + day.hour).getBoundingClientRect()
      this.clicked = true
      this.currentDayData = day
    },
    closeCreateDialog() {
      this.clicked = false
      this.currentDayData = {}
    },
    closeDeleteDialog() {
      this.clickedDelete = false
      this.currentDayData = {}
    },
    closeDetailCard() {
      this.openCard = false
      this.currentDayData = {}
    },
    openDeleteDialog({ day, event }) {
      this.currentElementPosition = document.getElementById(day.date + day.hour).getBoundingClientRect()
      this.clickedDelete = true
      this.currentDayData = day
      this.selectedEvent = event
    },
    redirectToEventDetails({ event: { id: eventId } }) {
      this.$router.push({ name: 'calendar-edit-event', params: { eventId } })
    },
    deleteActivity({ targetPeriodicGroup }) {
      const { organizationId, projectId } = this
      this.runAsync(() => this.$store.dispatch('activity/delete', { organizationId, projectId, activity: this.selectedEvent, targetPeriodicGroup }))
      this.clickedDelete = false
      this.selectedEvent = {}
    },
    getMoreEvents({ date }) {
      this.$store.commit('calendar/updateDate', date)
      this.$store.commit('calendar/updateRange', 'day')
      this.$nextTick(() => {
        if (this.$refs?.calendar) {
          this.scrollToTime()
          this.updateTime()
        }
      })
    },
    handleClickOnEvent(clickedEvent) {
      if (!this.doubleClickTimer) {
        this.doubleClickTimer = setTimeout(() => {
          this.openDetailsCard(clickedEvent)
        }, 100)
      } else {
        clearTimeout(this.doubleClickTimer)
        this.doubleClickTimer = null
        this.redirectToEventDetails(clickedEvent)
      }
    },
    formatEventDate(event) {
      return DateTime
        .fromISO(event.date.start.toDate().toISOString())
        .setLocale(this.$i18n.locale)
        .toFormat('ccc dd LLL yyyy')
    },
    formatEventTime(event) {
      const start = DateTime
        .fromISO(event.date.start.toDate().toISOString())
        .setLocale(this.$i18n.locale)
        .toFormat('t')
      const end = DateTime
        .fromISO(event.date.end.toDate().toISOString())
        .setLocale(this.$i18n.locale)
        .toFormat('t')
      return this.$t('project.academy.calendar.timeFromTo', { start, end })
    },
    async openDetailsCard(selectedEvent) {
      const { organizationId, projectId, teams } = this
      const { event, day } = selectedEvent
      this.currentElementPosition = document.getElementById(event.id).getBoundingClientRect()
      this.currentDayData = day
      const eventTeam = teams.find(team => team.id === event.teamId)
      const eventUsers = selectedEvent.event.type !== 'guildMatch'
        ? await this.$store.dispatch('academy/readUsersByTeam', { organizationId, projectId, teamId: eventTeam.id }) : null
      const title = selectedEvent.event.type === 'guildMatch' ? `${selectedEvent.event.teamsByIndex[0].name} - ${selectedEvent.event.teamsByIndex[1].name}` : event.type === 'training'
        ? eventTeam.fullTeamName
        : `${eventTeam.fullTeamName} - ${Object.values(event.teamsByIndex).find(team => team?.name)?.name}`

      this.cardData = {
        id: event.id,
        title,
        date: this.formatEventDate(event),
        time: this.formatEventTime(event),
        type: event.type,
        ...(selectedEvent.event.type !== 'guildMatch' && {
          coaches: eventUsers
            .filter(user => user.roles.byProperty[organizationId].byProject[projectId].byTeam[eventTeam.id].roles.some(role => role.includes('coach')))
            .map(({ firstName, lastName }) => `${firstName} ${lastName}`),
        }),
        parentsOnly: event?.userSources?.excludeTeamPlayers,
        footer: event?.description,
      }
      this.openCard = true
      clearTimeout(this.doubleClickTimer)
      this.doubleClickTimer = null
    },
    closeDetailsCard() {
      this.openCard = false
    },
    prevWeek() {
      this.$store.commit('calendar/updateDate', DateTime.fromISO(this.calendar).minus({ days: 7 }).toISODate().slice(0, 10))
      this.$emit('updateSelectedDate', null)
    },
    nextWeek() {
      this.$store.commit('calendar/updateDate', DateTime.fromISO(this.calendar).plus({ days: 7 }).toISODate().slice(0, 10))
      this.$emit('updateSelectedDate', null)
    },
    prevDay() {
      this.$store.commit('calendar/updateDate', DateTime.fromISO(this.calendar).minus({ days: 1 }).toISODate().slice(0, 10))
      this.$emit('updateSelectedDate', null)
    },
    nextDay() {
      this.$store.commit('calendar/updateDate', DateTime.fromISO(this.calendar).plus({ days: 1 }).toISODate().slice(0, 10))
      this.$emit('updateSelectedDate', null)
    },
    prevMonth() {
      this.$store.commit('calendar/updateDate', DateTime.fromISO(this.calendar).minus({ months: 1 }).toISODate().slice(0, 10))
      this.$emit('updateSelectedDate', null)
    },
    nextMonth() {
      this.$store.commit('calendar/updateDate', DateTime.fromISO(this.calendar).plus({ months: 1 }).toISODate().slice(0, 10))
      this.$emit('updateSelectedDate', null)
    },
    teamLabel(eventData) {
      return this.teams.filter(team => eventData.event.userSources.teamIds.includes(team.id))?.map(team => team.fullTeamName) ?? []
    },
  },
}
</script>
<style lang="scss" scoped>
.month-controls {
  position: absolute;
  width: calc(100% - 174px);
  z-index: 1;
}
</style>
